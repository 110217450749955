<template>
    <appbar ref="root" id="Appbar" fixed default :="modeprops" easing>
        <a href="/" branding rel="home" caps weight="400" scale="sm" gutters="0" title="Discover Surrey, BC">
            <span label>Discover Surrey, BC</span>
            <AppLogo alt="Discover Surrey BC" :focused="active" />
        </a>
        <button aria-label="Toggle Navigation" toggle noappearance @click="togglemode($event)">
            <IconMenu toggle :focused="active" />
        </button>
        <Suspense>
            <template #default>
                <rs-grid content easing aria-label="Main" :="modeprops">
                    <AppbarLinks v-if="!!linkgroups?.primary.length" type="primary" primary xxs-1 gap-24 :config="linkgroups?.primary" elastic />
                    <rs-grid locations xxs-1 gap-48 v-if="!!linkgroups?.secondary.length">
                        <AppbarLinks type="secondary" secondary xs-1 gap-24 :config="linkgroups?.secondary" inline elastic group />
                        <UIContent v-for="(item, index) in data?.components" :key="`block-${item?.id}`" :config="item.props" uicontent group />
                    </rs-grid>
                </rs-grid>
            </template>
            <template #fallback>
                <MDSkeleton>
                    <MDBlocker active="true" cursor="progress" type="default" />
                </MDSkeleton>
            </template>
        </Suspense>
    </appbar>
</template>

<script>
import { ref, computed, onBeforeMount, watch } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { useComponentdata, useNormalizeComponentProps } from '@/javascript/lib/composables'
import AppbarLinks from '@/components/AppbarLinks.ce.vue'
import Address from '@/components/Address.ce.vue'
import IconMenu from '@/components/IconMenu.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import AppLogo from '@/components/AppLogo.ce.vue'
import UIContent from '@/components/UIContent.ce.vue'

export default {
    name: "AppBar",
    components: {AppLogo, AppbarLinks, Address, IconMenu, MDBlocker, MDSkeleton, UIContent},
    props: {
        config: {
            type: String,
            default() {
                return null
            }
        },
        info: {
            type: String,
            default() {
                return null
            }
        },
        heading: {
            type: String,
            default() {
                return null
            }
        },
        active: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    setup(props) {
        const root      = ref(null)
        const store     = useStore()
        const data      = ref(null)
        const toggle    = ref(false)
        const addresses = ref(null)
        const layoutprops = ref(null)
        const linkgroups = ref(null)
        const active       = ref(false)
        const scrolling  = ref(false)
        const scrollingprops  = ref(null)
        const modeprops  = ref(null)
        const html       = ref(null)
        const observer  = ref(null)
        const togglemode = (e) => {
            store.menuactive = active.value = !active.value
            modeprops.value = !!active.value ? {active: ''} : {}
            if (!!scrolling.value) {
                modeprops.value = {...modeprops.value, scrolling: ''}
            }

            html.value?.classList.toggle('md-no-scroll', !!active.value)
        }

        watch(root, (n, o) => {
            html.value = document.querySelector('html')
            const el = [...document.querySelectorAll('rs-uielementalheader, [rs-elemental="uielementalpageheader"]')]?.shift()

            if (el instanceof HTMLElement) {
                const settings   = {
                    threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]//[0, 0.25, 0.5, 0.75, 1]
                }
                observer.value = new IntersectionObserver(entries => {
                    entries.map((entry) => {
                        const b = (entry.intersectionRatio <= 0.2)
                        scrolling.value = b
                        modeprops.value = b ? {scrolling: ''} : {}
                        // console.log('scrolling.value (observer)', scrolling.value, entry.intersectionRatio)
                    })
                }, settings)
                observer.value.observe(el)
            } else {
                modeprops.value = {scrolling: ''}
            }
        })

        watch(data, (newvalue, oldvalue) => {
            const {props}       = useNormalizeComponentProps(data)
            layoutprops.value   = {...props.value}
            if (data.value?.layout?.contentprops) {
                contentprops.value = {}
                data.value?.layout?.contentprops?.map( v => {
                    contentprops.value[v.key] = v.value
                });
            }

            // Link Groups
            if(data.value?.linkgroups?.length) {
                linkgroups.value = {}
                data.value.linkgroups.map( v => {
                    const k = v.key.replaceAll(/(^\w+-|-\w+$)/gi, '') // @todo refactor this so the pattern accounts for different combinations
                    linkgroups.value[k] = v.links
                })
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readGlobalComponent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            modeprops,
            togglemode,
            toggle,
            data,
            linkgroups,
            scrollingprops,
            scrolling,
            active
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
