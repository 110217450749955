<template>
    <rs-griditem span-xs-1 span-sm-5 span-xl-3 v-if="data?.layout?.id" v-bind="$attrs" term>
        <Picture stackable-item :config="data.image" :alt="data.image?.alt ?? data.title" rounded />
        <content>
            <rs-heading v-if="data.title" elastic>
                <h3 weight="regular" scale="subtitle-lg" branded gutters="0" caps>
                    <a v-for="(item, index) in data?.links" :href="item?.url" v-html="data?.title" />
                </h3>
            </rs-heading>
            <rs-content v-if="data.content" scale="body-sm" v-html="data.content"></rs-content>
        </content>
    </rs-griditem>
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue';
import { useNormalizeContentProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import Picture from '@/components/Picture.ce.vue'

export default {
    name: "Term",
    components: {MDButton, Picture},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data          = ref(null)
        const contentprops  = ref(null)
        const link          = ref(null)

        watch(data, (n, o) => {
            if (n) {
                const {properties}  = useNormalizeContentProps(data)
                contentprops.value  = {...properties.value}
                link.value          = [...data.value?.links ?? []]?.shift()
            }
        })

        onBeforeMount(() => {
            data.value = props.config?.props
        })

        return {
            data,
            link,
            contentprops
        }
    }
}
</script>
