import { ref, computed, onBeforeMount } from 'vue'
import { defineStore } from "pinia"

export const useStore = defineStore('store', () => {
    const observers = ref(new Set())
    const observer  = ref(null)
    const observe   = (element, callback) => {
        observers.value.add(element)
        if (observer.value && observer.value instanceof IntersectionObserver) {
            ([...observers.value.values()])?.map( (e) => {
                observer?.value.observe(e)
                if (callback instanceof Function) {
                    callback(e, observer.value)
                }
            })
        }
    }
    const baseurl = (['local', 'localhost'].includes(__umbrastate__.mode)) ? `https://dev.discoversurreybc.com` : ''
    const selectedmarker = ref(null)
    const svgmapdata = ref(null)
    const menuactive = ref(false)
    const mapstate = ref({
        data:           null,
        loading:        false,
        infoWindow:     null,
        activemarkers:  null,
        markers:        null,
        markerclick:    null,
        selection:      null,
        data:           null,
        dataset:        null,
        search:         null,
        searchcomplete: null,
        bounds:         null,
        infoWindowClick:     null
    })
    return {
        baseurl,
        observer, observers, observe,
        ...window?.__umbrastate__ ?? {},
        selectedmarker,
        svgmapdata,
        menuactive,
        mapstate
    }
})
