<template>
    <rs-container ref="root" rs-elemental="uielementalctagroup" :="layoutprops">
        <Suspense>
            <template #default>
                <rs-grid v-if="tiles?.length" tiles :="gridprops">
                    <rs-griditem :="contentattr" content v-if="(showtitle || showcontent || showlinks)">
                        <rs-heading v-if="showtitle">
                            <MDText tag="h2" heading ref="heading" scale="lg" weight="400" casing="display-sm" gutters="0" caps v-html="wrappedtitle"></MDText>
                        </rs-heading>
                        <rs-content v-if="showcontent" v-html="data?.content"></rs-content>
                        <rs-actions v-if="showlinks">
                            <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" contained maxed solid />
                        </rs-actions>
                    </rs-griditem>

                    <CTATile v-for="(item, index) in tiles" :config="item" easing />
                </rs-grid>
                <MDSkeleton v-else aspect-16-9 error="true" spin />
            </template>
            <template #fallback>
                <MDSkeleton aspect-16-9>
                    <MDBlocker active="true" cursor="default" type="default" />
                </MDSkeleton>
            </template>
        </Suspense>
    </rs-container>
</template>

<script>
import { ref, watch, computed, onBeforeMount } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { useWrappedString, useComponentdata, useNormalizeLayoutProps, useNormalizeGridProps } from '@/javascript/lib/composables'
import CTATile from './CTATile.vue'
import MDSkeleton from './MDSkeleton.ce.vue'
import MDButton from './MDButton.ce.vue'
import MDText from './MDText.vue'

export default {
    name: "UIElementalCTAGroup",
    components: {CTATile, MDSkeleton, MDButton, MDText},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const store         = useStore()
        const root          = ref(null)
        const data          = ref(null)
        const tiles         = ref(null)
        const layoutprops   = ref(null)
        const gridprops     = ref({})
        const contentattr   = ref({})
        const showcontent       = computed(() => !!data.value?.display?.displaycontent && !!data.value?.content)
        const showlinks         = computed(() => !!data.value?.display?.displaylinks && !!data.value?.links?.length)
        const showtitle         = computed(() => !!data.value?.display?.showtitle && !!data.value?.title)
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })

        watch(data, (newvalue, oldvalue) => {
            const {layout} = useNormalizeLayoutProps(data)
            const {grid, gap} = useNormalizeGridProps(data)
            layoutprops.value = {...layout.value}
            tiles.value = [...data.value?.components ?? []]

            if (!!gap.value) {
                gridprops.value[gap.value] = ''
            }

            // This is a bit of a hack to leverage the grid properties to define
            // the number of columns (responsive), where its original intention is
            // to define the number of grid columns the element should (responsively) span
            Object.keys(grid.value).map((v) => {
                let p = v.replace('span-', '')
                p = p.replace('xs-', 'xxs-')
                gridprops.value[p] = ''
                contentattr.value[v] = ''
            })
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null

            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readMosaic', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            tiles,
            data,
            contentattr,
            layoutprops,
            gridprops,
            showcontent,
            showlinks,
            showtitle,
            wrappedtitle
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>

