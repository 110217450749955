<template>
    <Suspense>
        <template #default>
            <template v-if="layoutprops?.template == 'default'">
                <rs-container ref="root" rs-elemental="uielementalcallout" :="layoutprops">
                    <rs-grid  xxs-1 sm-12 span-xs-6 ref="grid" :="gridprops">

                        <rs-griditem span-xs-1 span-sm-6 v-if="showtitle || showcontent || showlinks" content>
                            <rs-heading v-if="showtitle">
                                <h2 weight="regular" casing="display-sm" gutters="0" caps :focustext="focustext" v-html="wrappedtitle"></h2>
                                <h3 v-if="data?.subtitle" role="doc-subtitle" scale="subtitle-mm" weight="900" gutters="0" caps v-html="data?.subtitle"></h3>
                            </rs-heading>
                            <rs-content v-if="showcontent" :="contentprops" v-html="data.content" scale="subtitle-mm">
                            </rs-content>
                            <rs-actions v-if="showlinks">
                                <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" solid contained rounded />
                            </rs-actions>
                        </rs-griditem>

                        <rs-griditem span-xs-1 span-sm-6 v-if="image" media :="ratioprops">
                            <ImageMask stackable-item :config="image" :alt="image?.alt ?? data?.title" :clip-path-id="clipPathId" >
                                <defs>
                                    <clipPath :id="clipPathId">
                                        <path d="M254.7,577.8l-53.9,53.9c-7.8,7.8-7.8,20.5,0,28.3l53.9,53.9c7.8,7.8,20.5,7.8,28.3,0l53.9-53.9c7.8-7.8,7.8-20.5,0-28.3l-53.9-53.9c-7.8-7.8-20.5-7.8-28.3,0M626.4,576.9l-130,130c-7.8,7.8-7.8,20.5,0,28.3l130,130c7.8,7.8,20.5,7.8,28.3,0h0l130-130c7.8-7.8,7.8-20.5,0-28.3l-130-130c-7.8-7.8-20.5-7.8-28.3,0h0M744.9,459.4l-61.7,61.7c-7.8,7.8-7.8,20.5,0,28.3h0l61.7,61.7c7.8,7.8,20.5,7.8,28.3,0l61.7-61.7c7.8-7.8,7.8-20.5,0-28.3h0l-61.7-61.7c-7.8-7.8-20.5-7.8-28.3,0M102.3,424.4L5.9,520.9c-7.8,7.8-7.8,20.5,0,28.3l96.4,96.4c7.8,7.8,20.5,7.8,28.3,0l96.4-96.4c7.8-7.8,7.8-20.5,0-28.3l-96.4-96.4c-7.8-7.8-20.5-7.8-28.3,0M440.6,391.1l-130,130c-7.8,7.8-7.8,20.5,0,28.3h0l130,130c7.8,7.8,20.5,7.8,28.3,0l130-130c7.8-7.8,7.8-20.5,0-28.3h0l-130-130c-7.8-7.8-20.5-7.8-28.3,0M858.6,351.5l-62.3,62.3c-7.8,7.8-7.8,20.5,0,28.3l62.3,62.3c7.8,7.8,20.5,7.8,28.3,0l62.3-62.3c7.8-7.8,7.8-20.5,0-28.3l-62.3-62.3c-7.8-7.8-20.5-7.8-28.3,0M626.4,205.2l-130,130c-7.8,7.8-7.8,20.5,0,28.3l130,130c7.8,7.8,20.5,7.8,28.3,0h0l130-130c7.8-7.8,7.8-20.5,0-28.3l-130-130c-7.8-7.8-20.5-7.8-28.3,0h0M254.5,205l-130,130c-7.8,7.8-7.8,20.5,0,28.3h0l130,130c7.8,7.8,20.5,7.8,28.3,0h0l130-130c7.8-7.8,7.8-20.5,0-28.3h0l-130-130c-7.8-7.8-20.5-7.8-28.3,0h0M441.1,134.8l-70.7,70.7c-7.8,7.8-7.8,20.5,0,28.3l70.7,70.7c7.8,7.8,20.5,7.8,28.3,0l70.7-70.7c7.8-7.8,7.8-20.5,0-28.3l-70.7-70.7c-7.8-7.8-20.5-7.8-28.3,0h0Z"/>
                                    </clipPath>
                                </defs>
                                <g>
                                    <rect fill="#F0F0F0" x="737.6" y="519.9" width="63.5" height="63.5" rx="18.7" ry="18.7" transform="translate(-164.7 705.6) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="768.7" y="592.1" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(-218.8 778.2) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="886.4" y="686.9" width="90.4" height="90.4" rx="18.7" ry="18.7" transform="translate(-244.8 873.2) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="612.5" y="551.5" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(-235.9 655.8) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="667.1" y="693.6" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(-320.4 736.1) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="818.2" y="757.4" width="69.6" height="69.6" rx="18.7" ry="18.7" transform="translate(-310.3 835.2) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="781.8" y="420" width="96.4" height="96.4" rx="18.7" ry="18.7" transform="translate(-88 724.1) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="858.7" y="518.7" width="65.7" height="65.7" rx="18.7" ry="18.7" transform="translate(-128.8 792) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="940.4" y="523.9" width="170" height="170" rx="18.7" ry="18.7" transform="translate(-130.2 903.4) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="1193.7" y="416.6" width="63.5" height="63.5" rx="18.7" ry="18.7" transform="translate(41.9 997.8) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="1103.8" y="285.5" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(96 925.3) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="1018.3" y="222.7" width="90.4" height="90.4" rx="18.7" ry="18.7" transform="translate(122.1 830.5) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="1260" y="326.1" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(113.1 1047.7) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="1205.4" y="184" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(197.6 967.4) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="1107.2" y="173" width="69.6" height="69.6" rx="18.7" ry="18.7" transform="translate(187.5 868.4) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="1116.6" y="483.5" width="96.4" height="96.4" rx="18.7" ry="18.7" transform="translate(-34.8 979.4) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="1070.5" y="415.7" width="65.7" height="65.7" rx="18.7" ry="18.7" transform="translate(6 911.6) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="891.4" y="305.2" width="170" height="170" rx="18.7" ry="18.7" transform="translate(10.1 804.7) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="142.9" y="519.9" width="63.5" height="63.5" rx="18.7" ry="18.7" transform="translate(-338.9 285) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="173.9" y="592" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(-393 357.5) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="291.5" y="687" width="90.4" height="90.4" rx="18.7" ry="18.7" transform="translate(-419.1 452.5) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="17.7" y="551.5" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(-410.2 235.2) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="72.2" y="693.6" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(-494.7 315.4) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="223.2" y="757.3" width="69.6" height="69.6" rx="18.7" ry="18.7" transform="translate(-484.6 414.4) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="187" y="420" width="96.4" height="96.4" rx="18.7" ry="18.7" transform="translate(-262.2 303.4) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="263.7" y="518.6" width="65.7" height="65.7" rx="18.7" ry="18.7" transform="translate(-303.1 371.2) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="345.5" y="523.9" width="170" height="170" rx="18.7" ry="18.7" transform="translate(-304.5 482.7) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="598.7" y="416.6" width="63.5" height="63.5" rx="18.7" ry="18.7" transform="translate(-132.4 577.2) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="508.8" y="285.6" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(-78.3 504.7) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="423.3" y="222.7" width="90.4" height="90.4" rx="18.7" ry="18.7" transform="translate(-52.2 409.7) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="665" y="326.1" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(-61.1 627) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="610.4" y="183.9" width="122.4" height="122.4" rx="18.7" ry="18.7" transform="translate(23.4 546.7) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="512.3" y="173" width="69.6" height="69.6" rx="18.7" ry="18.7" transform="translate(13.3 447.7) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="521.6" y="483.5" width="96.4" height="96.4" rx="18.7" ry="18.7" transform="translate(-209.1 558.7) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="475.7" y="415.7" width="65.7" height="65.7" rx="18.7" ry="18.7" transform="translate(-168.2 490.9) rotate(-45)"/>
                                    <rect fill="#F0F0F0" x="296.5" y="305.3" width="170" height="170" rx="18.7" ry="18.7" transform="translate(-164.2 384) rotate(-45)"/>
                                </g>
                            </ImageMask>
                        </rs-griditem>
                    </rs-grid>
                </rs-container>
            </template>

            <template v-if="layoutprops?.template == 'simple'">
                <rs-container ref="root" rs-elemental="uielementaleventshowcase" :="layoutprops">
                    <rs-grid  xxs-1 sm-12 span-xs-6 ref="grid" :="gridprops">
                        <rs-griditem v-if="showtitle || showcontent" :="gridprops" content>
                            <rs-heading v-if="showtitle">
                                <h2 v-if="link?.url" casing="display-sm" gutters="0" caps elastic><a :href="link?.url" :title="title" v-html="title"></a></h2>
                                <h2 v-else casing="display-sm" gutters="0" caps elastic v-html="wrappedtitle"></h2>
                            </rs-heading>
                        </rs-griditem>
                        <rs-griditem :="gridprops" media rounded radius-sm>
                            <Picture v-if="!!image" :config="image" :alt="image?.alt ?? data?.title" easing stackable-item />
                        </rs-griditem>
                    </rs-grid>
                </rs-container>
            </template>
        </template>
        <template #fallback>
            <MDSkeleton aspect-21-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useWrappedString , useComponentdata, useNormalizeLayoutProps, useNormalizeGridProps, useNormalizeRatioProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import ImageMask from '@/components/ImageMask.ce.vue'
import Picture from '@/components/Picture.ce.vue'
import { uuid } from '@/javascript/lib/utils.js'
import { useStore } from '@/javascript/lib/store'

export default {
    name: "UIElementalCallout",
    components: {MDButton, MDSkeleton, MDBlocker, ImageMask, Picture},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const store         = useStore()
        const data          = ref(null)
        const layoutprops   = ref(null)
        const ratioprops    = ref(null)
        const contentprops  = ref(null)
        const gridprops     = ref(null)
        const grid          = ref(null)
        const image         = ref(null)
        const showcontent   = computed(() => data.value?.display?.displaycontent)
        const showtitle     = computed(() => data.value?.display?.showtitle)
        const showlinks     = computed(() => data.value?.display?.displaylinks && data.value?.links?.length)
        const clipPathId = `clip-path-${uuid()}` // Generate a unique ID for the clipPath element
        const focustext     = computed(() => ([...data.value?.focustext] ?? [])?.length ? true : null)
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })

        watch(data, (newvalue, oldvalue) => {
            const {layout}      = useNormalizeLayoutProps(data)
            const {ratios}      = useNormalizeRatioProps(data)
            const {grid, gap}   = useNormalizeGridProps(data)
            // Extract the ratios as a value object, but DON'T combine with `layoutprops`
            ratioprops.value    = {...ratios.value}
            layoutprops.value   = {...layout.value, ...grid.value}

            gridprops.value = {...grid.value}
            if (!!gap.value) {
                gridprops.value[gap.value] = ''
            }

            image.value = {...data.value?.image}
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readComponent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            grid,
            gridprops,
            layoutprops,
            ratioprops,
            contentprops,
            focustext,
            showtitle,
            showcontent,
            showlinks,
            image,
            clipPathId,
            wrappedtitle
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
