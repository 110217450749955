<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementalcontent" :="layoutprops">
                <rs-heading v-if="showtitle">
                    <h2 weight="regular" casing="display-sm" gutters="0" caps v-html="wrappedtitle"></h2>
                    <h3 v-if="data?.subtitle" role="doc-subtitle" scale="subtitle-mm" weight="900" gutters="0" caps v-html="data?.subtitle"></h3>
                </rs-heading>
                <rs-content v-if="showcontent" :="contentprops">
                    <slot></slot>
                </rs-content>
                <rs-actions v-if="showlinks">
                    <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" :type="index == 0 ? 'contained' : 'outlined'" chunky outlined solid />
                </rs-actions>
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-21-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useWrappedString , useComponentdata, useNormalizeComponentProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import { useStore } from '@/javascript/lib/store'

export default {
    name: "UIElementalContent",
    components: {MDButton, MDSkeleton, MDBlocker},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const store         = useStore()
        const data          = ref(null)
        const layoutprops   = ref(null)
        const contentprops  = ref(null)
        const showcontent   = computed(() => data.value?.display?.displaycontent)
        const showtitle     = computed(() => data.value?.display?.showtitle)
        const showlinks     = computed(() => data.value?.display?.displaylinks && data.value?.links?.length)
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {props}       = useNormalizeComponentProps(data)
                layoutprops.value   = {...props.value}
                const r = new RegExp('(^[0-9]+)')
                if (!!layoutprops.value?.label && !(r.test(layoutprops?.value?.label))) {
                    layoutprops.value[layoutprops.value.label] = ''
                    delete layoutprops.value.label
                }

                if (data.value?.layout?.contentprops) {
                    contentprops.value = {}
                    data.value?.layout?.contentprops?.map( v => {
                        contentprops.value[v.key] = v.value
                    });
                }
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readComponent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            layoutprops,
            contentprops,
            showtitle,
            showcontent,
            showlinks,
            wrappedtitle
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
