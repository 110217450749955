<script>
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from '@/javascript/lib/store'

export default {
    name: "App",
    components: {},
    props: {
        config: {
            type: String,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const store         = useStore()
        const threshold     = [0, 0.25, 0, 0.75, 1]
        const observer      = new IntersectionObserver((entries) => {
            entries.map((entry) => {
                window.requestAnimationFrame(() => {
                    entry.target.toggleAttribute('in-viewport', entry.isIntersecting)
                })
            })
        }, {threshold : threshold})
        store.observer = observer

        return {
        }
    }
}
</script>
