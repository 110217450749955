<template>
    <rs-griditem ref="root" tile :="tileprops" :rs-index="index" :content="template" :title="link?.title" :stackable="template === false ? true : null" @click.prevent.stop="clickhandler">
        <template v-if="template == 'tile_content'">
            <rs-heading v-if="showtitle">
                <h2 v-if="link?.url" heading scale="lg" weight="regular" casing="display-mm" gutters="0" caps><a :href="link?.url" :title="link?.title" v-html="wrappedtitle"></a></h2>
                <h2 v-else heading scale="lg" weight="regular" casing="display-mm" gutters="0" caps v-html="wrappedtitle"></h2>
            </rs-heading>
        </template>
        <template v-if="template == 'tile'">
            <content stackable-item>
                <rs-heading  v-if="showtitle" elastic>
                    <h3 v-if="link?.url" :casing="casing" gutters="0" caps><a :href="link?.url" :title="link?.title" v-html="wrappedtitle"></a></h3>
                    <h3 v-else :casing="casing" gutters="0" caps v-html="wrappedtitle"></h3>
                </rs-heading>
                <rs-content v-if="showcontent" casing="display-xxs" gutters="0" caps v-html="data?.content" elastic></rs-content>
                <rs-actions v-if="showlinks">
                    <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" :type="index == 0 ? 'contained' : 'outlined'" />
                </rs-actions>
            </content>
        </template>
        <template v-if="template === 'tile_locality'">
            <content stackable-item>
                <rs-heading v-if="showtitle">
                    <h2 v-if="link?.url" casing="display-sm" gutters="0" caps elastic><a :href="link?.url" :title="link?.title" v-html="wrappedtitle"></a></h2>
                    <h2 v-else casing="display-sm" gutters="0" caps elastic v-html="wrappedtitle"></h2>
                </rs-heading>
                <rs-content v-if="showcontent" scale="body-mm" gutters="0" v-html="data?.content"></rs-content>
                <rs-actions v-if="showlinks">
                        <MDButton v-for="(item, index) in data?.links" key: tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" contained white short/>
                </rs-actions>
            </content>
        </template>
        <template v-if="image?.id">
            <Picture stackable-item :config="image" :alt="image?.alt ?? data?.title" />
        </template>
        <template v-if="image?.id">
            <Picture stackable-item :config="image" :alt="image?.alt ?? data?.title" />
        </template>
    </rs-griditem>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useWrappedString, useNormalizeLayoutProps, useNormalizeRatioProps } from '@/javascript/lib/composables'
import Picture from '@/components/Picture.ce.vue'
import MDButton from '@/components/MDButton.ce.vue'

export default {
    name: "MosaicTile",
    components: {Picture, MDButton},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        },
        template: {
            type: String,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const data          = ref(null)
        const image         = ref(null)
        const link          = ref(null)
        const layoutprops   = ref(null)
        const tileprops     = ref(null)
        const contentprops  = ref(null)
        const tileratios    = ref(null)
        const showcontent   = computed(() => !!data.value?.display?.displaycontent && data.value?.content)
        const showlinks     = computed(() => !!data.value?.display?.displaylinks && !!data.value?.links?.length)
        const showtitle     = computed(() => !!data.value?.display?.showtitle && !!data.value?.title)
        const casing        = ref(null)

        // return dynamic varible
        const template      = computed(() => data.value?.layout?.contentprops?.find(prop => prop.key === 'template')?.value)

        const wrappedtitle  = computed(() => {
            return data.value?.title ? useWrappedString(data.value.title, [...data.value?.focustext ?? []]) : null
        })

        const clickhandler = (e) => {
            window.location = link.value?.url ?? window.location
        }

        watch(data, (newvalue, oldvalue) => {
            const {layout} = useNormalizeLayoutProps(data)
            layoutprops.value = {...layout.value}

            const {ratios} = useNormalizeRatioProps(data)
            tileratios.value = {...ratios.value ?? {}}

            // Build an grid attribute list and merge with tileratios
            tileprops.value = tileprops.value ?? {}
            data.value?.grid?.span?.map( v => {
                let p = v.replace('span-xs-', 'span-xxs-')
                tileprops.value[p] = ''
            })

            contentprops.value  = contentprops.value ?? {}
            data.value?.layout?.contentprops?.map( v => {
                contentprops.value[v.key] = v.value
            })

            // Heading Casing
            casing.value = (contentprops.value?.template !== 'tile') ? 'display-mm' : 'display-xs'

            // Link
            link.value = [...data.value?.links]?.shift()

            // Background image
            image.value = {...data.value?.image}

            tileprops.value = {
                ...layoutprops.value ?? {},
                ...contentprops.value ?? {},
                ...tileprops.value ?? {},
                ...tileratios.value ?? {},
            }

            if (!!image.value?.id) {
                tileprops.value = {
                    ...tileprops.value,
                    media: ''
                }
            }

            if (link.value?.url) {
                tileprops.value = {
                    ...tileprops.value,
                    linked: ''
                }
            }

            // Background color replacement
            if (!!data.value?.display?.backgroundcolor) {
                const c = data.value?.display?.backgroundcolor
                tileprops.value = {
                    ...tileprops.value,
                    style: `background-color: ${c};`
                }
            }

        })

        onBeforeMount(() => {
            if((props.config?.constructor !== Object)) {
                data.value = JSON.parse(props.config)?.props
            }else {
                data.value = props.config?.props
            }
        })
        return {
            root,
            data,
            showcontent,
            showlinks,
            showtitle,
            tileprops,
            layoutprops,
            wrappedtitle,
            casing,
            image,
            link,
            clickhandler,
            template
        }
    }
}
</script>
