<template>
    <Suspense>
        <template #default>
            <uicontent v-if="data?.layout?.key" v-bind="$attrs" rs-grid :="gridprops">
                <uicontenttitle v-if="showtitle" rs-griditem span-xs-1>
                    <MDText scale="xs" gutters="0" weight="700" caps :="headingprops" v-html="data?.title" />
                </uicontenttitle>
                <uicontentcontent v-if="showcontent" v-html="data?.content" rs-griditem span-xs-1></uicontentcontent>
                <uicontentaction v-if="showlinks" rs-griditem span-xs-1>
                    <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" weight="700" contained solid />
                </uicontentaction>
            </uicontent>
            <MDSkeleton v-else error="true" aspect-4-3 spin />
        </template>
        <template #fallback>
            <MDSkeleton aspect-4-3>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, onBeforeMount, watch } from 'vue';
import { useNormalizeLayoutProps, useNormalizeGridProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import MDText from '@/components/MDText.vue'

export default {
    name: "UIContent",
    components: {MDButton, MDText},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const data = ref(null)
        const showcontent       = computed(() => !!data.value?.display?.displaycontent)
        const showlinks         = computed(() => !!data.value?.display?.displaylinks && !!data.value?.links?.length)
        const showtitle         = computed(() => !!data.value?.display?.showtitle)
        const layoutprops       = ref(null)
        const gridprops         = ref(null)
        const headingprops            = computed(() => {
            const k = data.value?.layout?.key ?? null
            switch(k) {
                case 'help':
                    return {tag: 'h2', casing: 'display-sm', center: ''}
                default:
                    return {tag: 'h3'}
            }
        })

        watch(data, (n, o) => {
            if (n) {
                const {layout} = useNormalizeLayoutProps(data)
                layoutprops.value = {...layout.value}

                if(data.value?.grid?.span) {
                    const {grid} = useNormalizeGridProps(data)
                    gridprops.value = {...grid.value, ...{id: `uicontent-${data.value?.layout?.key}`}}
                }
            }
        })

        onBeforeMount(() => {
            data.value = props.config
        })

        return {
            data,
            headingprops,
            gridprops,
            showcontent,
            showlinks,
            showtitle
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>

