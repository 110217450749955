<template>
    <a :href="config.url ?? '#'" :title="config.title" easing>
        <span :class="`${csskey}--link ${csskey}--link-label`" gutters="0" :casing="casing" caps easing label v-html="config.title" />
        <span :class="`${csskey}--link ${csskey}--link-description`" gutters="0" display="body-sm" easing description v-html="description" />
    </a>
</template>

<script>
import { computed, onBeforeMount, watch, ref } from 'vue';

export default {
    name: "AppbarNavItem",
    components: {},
    props: {
        type: {
            type: String,
            default() {
                return null
            }
        },
        casing: {
            type: String,
            default() {
                return 'nav'
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const csskey = computed(() => {
            switch(props.type) {
                case 'primary':
                    return `md-primarynav`
                case 'secondary':
                    return `md-secondarynav`
                case 'utility':
                    return `md-utilitynav`
            }
        })
        const data = ref(null)
        const description = ref(null)

        watch(data, (n, o) => {
            if (!!n && (props.type == 'primary')) {
                description.value = data.value?.description
            }
        })

        onBeforeMount(() => {
            data.value = {...props.config ?? {}}
        })

        return {
            data,
            csskey,
            description
        }
    }
}
</script>
