<template>
    <Suspense>
        <template #default>
            <rs-container v-if="partners?.length" ref="root" rs-elemental="uielementaltaxonomymap" :="layoutprops">
                <rs-grid :="gridprops"  xxs-1 sm-12 span-sm-12>
                    <rs-griditem span-xs-1 content>
                        <rs-heading v-if="showtitle">
                            <MDText tag="h2" heading ref="heading" scale="lg" weight="400" :casing="casing" gutters="0" caps v-html="wrappedtitle"></MDText>
                        </rs-heading>
                    </rs-griditem>
                    <rs-griditem span-xs-1 terms>
                        <rs-content v-if="!!terms?.length">
                            <ul termslist listreset>
                                <li v-for="(item, index) in terms" :key="`termitem-${index}`">
                                    <MDButton :href="item?.urlsegment" :key="`term-${index}`" :label="item?.title" chip outlined />
                                </li>
                            </ul>
                        </rs-content>
                        <rs-actions v-if="showlinks">
                            <a ref="showmorelink" :href="link?.url" :target="link?.openinnewwindow ? '_blank' : null" :key="link?.key" v-html="link?.title" casing="display-xs" />
                        </rs-actions>
                    </rs-griditem>
                    <rs-griditem span-xs-1 span-sm-12 media>
                        <glider v-if="partners?.length" ref="glidercontainer" stackable-item>
                            <div class="glider-track">
                                <template v-for="(item, index) in partners" :key="`slide-${index}`">
                                    <PartnerSlide :ref="(el) => addasset(el)" :pos="index" :config="item" @click.prevent.stop="clickhandler"></PartnerSlide>
                                </template>
                            </div>
                        </glider>
                    </rs-griditem>
                    <template v-if="!!config?.controls">
                        <rs-griditem span-xs-1 span-sm-12 controls>
                            <glider-controls>
                                <pagination ref="pagination" role="tablist" bars relative></pagination>
                            </glider-controls>
                        </rs-griditem>
                    </template>
                    <rs-griditem span-xs-1 span-sm-12 map ref="mapcontainer">
                        <Drawer v-if="selectedpartner" ref="panel" :="active" easing :config="selectedpartner" v-memo="[selectedpartner]"></Drawer>
                        <GoogleMap ref="map" :key="apikey" :markers="markers" :selected="selectedmarker" :="active"></GoogleMap>
                    </rs-griditem>
                </rs-grid>
            </rs-container>
            <MDSkeleton v-else aspect-16-9 error="true" spin />
        </template>
        <template #fallback>
            <MDSkeleton aspect-16-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount, onMounted, nextTick, toRaw, isProxy } from 'vue'
import { animate } from "popmotion"
import { useTick } from '@/javascript/lib/tick'
import { useStore } from '@/javascript/lib/store'
import { clamp } from '@/javascript/lib/utils'
import { useWrappedString, useComponentdata, useNormalizeRatioProps, useNormalizeLayoutProps, useNormalizeGridProps, useNormalizeContentProps } from '@/javascript/lib/composables'
import Glider from 'glider-js'
import Picture from '@/components/Picture.ce.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDButton from '@/components/MDButton.ce.vue'
import MDText from '@/components/MDText.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import PartnerSlide from '@/components/PartnerSlide.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import Drawer from '@/components/Drawer.vue'

export default {
    name: "UIElementalTaxonomyMap",
    components: {Picture, PartnerSlide, MDButton, MDText, MDBlocker, MDSkeleton, GoogleMap, Drawer},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const __tick            = ref(null)
        const __autoplayNext    = ref(0)

        const root              = ref(null)
        const store             = useStore()
        const glidercontainer   = ref(null)
        const glider            = ref(null)
        const assets            = ref(new Set())
        const addasset          = async (asset) => {
            if(!assets.value?.has(asset) && (assets.value?.size < partners.value?.length)) {
                assets.value?.add(asset)
                if ((assets.value?.size == partners.value?.length) && config.value) {
                    await nextTick()
                    setupGlider(config.value)
                }
            }
        }

        const showcontent       = computed(() => !!data.value?.display?.displaycontent && !!data.value?.content)
        const showlinks         = computed(() => !!data.value?.display?.displaylinks && (!!data.value?.links?.length || !!addlpartners.value?.length))
        const showtitle         = computed(() => !!data.value?.display?.showtitle && !!data.value?.title)
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        const data          = ref(null)
        const layoutprops   = ref({})
        const gridprops     = ref(null)
        const config        = ref(null)
        const partners      = ref(null)
        const partnerdata   = ref(null)
        const addlpartners  = ref([])

        const laststep      = ref(0)
        const progress      = ref(null)
        const controls      = ref(null)
        const controlprev   = ref(null)
        const controlnext   = ref(null)
        const pagination    = ref(null)
        const link          = ref(null)
        const showmorelink  = ref(null)
        const terms         = ref(null)
        const meta          = ref({
            title:       null,
            index:       null,
            totalslides: null,
            progress:    0
        })

        // Google maps
        const casing        = ref(null)
        const panel         = ref(null)
        const active        = ref(null)
        const apikey        = ref(null)
        const center        = ref(null)
        const markers       = ref(null)
        const selectedmarker = ref(null)
        const selectedpartner = ref(null)
        const map           = ref(null)
        const mapcontainer  = ref(null)

        /**
         * Get a slide index by its ID
         *
         * @param {number} id The ID of a given slide
         * @returns {number} Returns the resulting index if found, 0 if otherwise
         */
        const getSlideIndexByID = (id) => {
            return [...partners.value?.keys()].findIndex(a => a == id) ?? 0
        }

        /**
         * Get a slide object by index
         *
         * @param {number} index Slide index
         * @returns object|null Returns an object literal representing a slide
         */
        const getSlideByIndex = (index) => {
            return [...partners.value?.values()][index] ?? null
        }

        const setupGlider = (c) => {
            const conf = {
                ...c,
                rewind: c?.loop,
                skipTrack: true,
                dots: c?.controls ? pagination.value : null,
                arrows: {
                    prev: c.controls ? controlprev.value : null,
                    next: c.controls ? controlnext.value : null
                },
                responsive: [
                    {
                        breakpoint: 1920,
                        settings: {
                            slidesToShow: 3.25,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2.25,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 2.25,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 320,
                        settings: {
                            slidesToShow: 1.25,
                            slidesToScroll: 1,
                        }
                    }
                ]
            }

            const inst = glidercontainer.value
            glider.value = new Glider(inst, conf)

            // Initial update
            updateGliderMeta()

            // Autoplay
            if (conf?.autoplay) {
                autoplay()
            } else {
                // Scroll event support
                inst?.addEventListener('glider-slide-visible', (e) => {
                    updateGliderMeta()
                })
            }
        }

        const tween = async (v) => {
            const from  = (laststep.value)
            const to    = ((glider.value.slide + 1) / assets.value.size) * 100

            await nextTick()
            animate({
                from: from,
                to: to,
                type: 'spring',
                duration: 1500,
                onUpdate: latest => meta.value.progress = latest,
                onComplete: () => laststep.value = to
            })
        }

        const autoplay = async () => {
            const gi = glider.value

            __tick.value = useTick(60, (config.value?.autoplaydelay) ?? 5)
            __tick.value.addEventListener('onTick', (e) => {
                meta.value.progress = e.detail?.p
                // console.log(`> --------------------------- >> (debug) ${e.detail.debug}`)
            })
            __tick.value.addEventListener('onTickInterval', (e) => {
                __autoplayNext.value = ((gi.slide + 1) < (assets.value?.size - 1)) ? __autoplayNext.value + 1 : 0
                // console.clear()
                // console.group('onTickInterval')
                // console.log('>')
                // console.log(`> --------------------------- >> (${config.value?.autoplaydelay})`, gi.slide, (!gi.slide), __autoplayNext.value)
                // console.log(`> --------------------------- >> (count) ${gi.slide + 1} -> ${assets.value?.size - 1}`)
                // console.log(`> --------------------------- >> (debug) ${e.detail.debug}`)
                // console.log(`> --------------------------- >> (detail)`, e.detail)
                // console.log('>')
                // console.groupEnd()
                gi.scrollItem(__autoplayNext.value)
                updateGliderMeta()
                // meta.value.progress = 0
            })
            await nextTick()
            __tick.value.start()
        }

        const updateGliderMeta = () => {
            const gi        = glider.value
            const index     = gi?.slide ?? 0
            const slide     = getSlideByIndex(index)
            const i         = ((index * 2) / assets.value?.size) * 100
            meta.value.title        = `${index + 1} / ${assets.value?.size} - ${slide?.props?.title}`
            meta.value.index        = (index + 1)
            meta.value.totalslides  = assets.value?.size
            meta.value.progress     = clamp(i, 0, 100)

            if(!config?.autoplay) {
                tween(meta.value.progress)
            }
        }

        const clickhandler = (e) => {
            const el = e.target.closest('slide');
            const uuid = el.getAttribute('uuid');
            store.selectedmarker = uuid
        }

        /**
         * Display the partner record in the map.
         *
         * @param {string} uuid A unique ID coresponding to the selected Partner entry
         * @param {boolean} autoscroll Auto-scroll to the map after selection?
         */
        const setSelectedPartner  = (uuid, autoscroll = false) => {
            if (uuid) {
                // Selections
                selectedpartner.value = getPartnerData(uuid)

                // Set the active property for the drawer
                active.value = {active: ''}

                // Scroll to the map component
                if (!!autoscroll) {
                    window.requestAnimationFrame(() => {
                        mapcontainer.value?.scrollIntoView({
                            behavior: 'smooth'
                        })
                    })
                }
            }
        }

        const getPartnerData = (id) =>  partnerdata.value?.has(id) ? partnerdata.value?.get(id) : null

        const reset = () => {
            selectedpartner.value   = null
            active.value            = null
            markers.value           = data.value?.components.map( v => {
                return {...v?.props?.map}
            })
        }

        watch(root, (n, o) => {
            if(n instanceof HTMLElement) {
                store.observe(n)
            }
        })

        watch(showmorelink, (n, o) => {
            if(n instanceof HTMLElement) {
                showmorelink.value.addEventListener('click', (e) => {
                    e.preventDefault()
                    const l = toRaw({...[...data.value?.links ?? []]?.shift() ?? {}})
                    const el = l?.url ? document.querySelector(l?.url) : null
                    if (el instanceof HTMLElement) {
                        window.requestAnimationFrame(() => {
                            el?.scrollIntoView({
                                behavior: 'smooth'
                            })
                        })
                    }
                })
            }
        })

        watch(() => store.selectedmarker, (n,o) => {
            if (store.selectedmarker) {
                setSelectedPartner (store.selectedmarker, true)
            } else {
                active.value = null
            }
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {layout} = useNormalizeLayoutProps(data)
                const {ratios} = useNormalizeRatioProps(data)
                const {grid, gap} = useNormalizeGridProps(data)
                layoutprops.value = {...layout.value, ...ratios.value, ...grid.value}
                gridprops.value = {...grid.value}
                if (!!gap.value) {
                    gridprops.value[gap.value] = ''
                }

                // This sets the heading scale based on the typescale value.
                // However, it does NOT change the tag, only the attribute that
                // determines the overall font size.
                casing.value = `display-${data.value?.display?.typescale ?? 'mm'}`

                partners.value      = newvalue?.components
                config.value        = newvalue?.glider

                // Store a map of the loaded partners for quick retrieval by key
                partnerdata.value = new Map();
                newvalue?.components?.map( v => {
                    const uuid = v.props?.map?.uuid
                    if (uuid) {
                        partnerdata.value.set(uuid, v)
                    }
                })

                // content props
                const {properties} = useNormalizeContentProps(data)
                terms.value = properties.value?.terms ? JSON.parse(properties.value.terms) : null

                // Links
                link.value = {...[...data.value?.links ?? []]?.shift() ?? {}}
                if (!!link.value?.url.startsWith('#')) {
                    link.value.url = `${window.location.pathname}${link.value.url}`
                }

                // Google maps
                apikey.value = store?.googlemaps_api_key
                markers.value = newvalue?.components?.map( v => {
                    return {...v?.props?.map}
                })
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null

            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readMapComponent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            terms,
            data,
            link,
            showmorelink,
            layoutprops,
            gridprops,
            showtitle,
            showcontent,
            showlinks,
            wrappedtitle,
            partners,
            assets,
            addasset,
            glidercontainer,
            config,
            meta,
            progress,
            controls,
            controlprev,
            controlnext,
            pagination,
            panel,
            apikey,
            markers,
            selectedmarker,
            selectedpartner,
            clickhandler,
            map,
            mapcontainer,
            active,
            casing
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
