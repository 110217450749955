<template>
    <Suspense>
        <template #default>
            <rs-container v-if="frames?.length" ref="root" rs-elemental="uielementalevents" :="layoutprops">
                <rs-grid :="gridprops"  xxs-1 sm-12 span-sm-12>
                    <rs-griditem span-xs-1 span-sm-8 media>
                        <glider v-if="frames?.length" ref="glidercontainer" stackable-item>
                            <div class="glider-track">
                                <template v-for="(item, index) in frames" :key="`slide-${index}`">
                                    <EventSlide :ref="(el) => addasset(el)" :pos="index" :config="item"></EventSlide>
                                </template>
                            </div>
                        </glider>
                    </rs-griditem>

                    <rs-griditem span-xs-1 span-sm-4 content>
                        <rs-heading v-if="showtitle">
                            <MDText tag="overline" v-if="showoverline" overline underlined scale="body-xs" caps weight="700" :label="data?.overline" />
                            <MDText tag="h2" heading ref="heading" scale="lg" weight="400" casing="display-sm" gutters="0" caps v-html="wrappedtitle"></MDText>
                        </rs-heading>
                        <rs-content v-if="showcontent" v-html="data?.content"></rs-content>
                        <rs-content eventlist v-if="showlinks">
                            <Event v-for="(item, index) in addlframes" :key="`event-${index}`" :config="item"></Event>
                        </rs-content>
                        <rs-actions v-if="showlinks">
                            <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" :type="index == 0 ? 'contained' : 'outlined'" solid contained maxed />
                        </rs-actions>
                        <template v-if="!!config?.controls">
                            <glider-controls>
                                <button ref="controlprev" prev aria-label="Previous" class="glider-prev" stackable>
                                    <icon class="md-icon" easing stackable-item>chevron_left</icon>
                                </button>
                                <pagination ref="pagination" role="tablist" bars relative></pagination>
                                <button ref="controlnext" next aria-label="Next" class="glider-next" stackable>
                                    <icon class="md-icon" easing stackable-item>chevron_right</icon>
                                </button>
                            </glider-controls>
                        </template>
                    </rs-griditem>
                </rs-grid>
            </rs-container>
            <MDSkeleton v-else aspect-16-9 error="true" spin />
        </template>
        <template #fallback>
            <MDSkeleton aspect-16-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount, onMounted, nextTick } from 'vue'
import { animate } from "popmotion"
import { useStore } from '@/javascript/lib/store'
import { useTick } from '@/javascript/lib/tick'
import { clamp } from '@/javascript/lib/utils'
import { useWrappedString, useComponentdata, useNormalizeRatioProps, useNormalizeLayoutProps, useNormalizeGridProps } from '@/javascript/lib/composables'
import Picture from '@/components/Picture.ce.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDButton from '@/components/MDButton.ce.vue'
import MDText from '@/components/MDText.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import EventSlide from '@/components/EventSlide.vue'
import Event from '@/components/Event.vue'
import Glider from 'glider-js'

export default {
    name: "UIElementalEvents",
    components: {Picture, EventSlide, Event, MDButton, MDText, MDBlocker, MDSkeleton},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const __tick            = ref(null)
        const __autoplayNext    = ref(0)

        const root              = ref(null)
        const store             = useStore()
        const glidercontainer   = ref(null)
        const glider            = ref(null)
        const assets            = ref(new Set())
        const addasset          = async (asset) => {
            if(!assets.value?.has(asset) && (assets.value?.size < frames.value?.length)) {
                assets.value?.add(asset)
                if ((assets.value?.size == frames.value?.length) && config.value) {
                    await nextTick()
                    setup(config.value)
                }
            }
        }

        const showcontent       = computed(() => !!data.value?.display?.displaycontent && !!data.value?.content)
        const showlinks         = computed(() => !!data.value?.display?.displaylinks && (!!data.value?.links?.length || !!addlframes.value?.length))
        const showoverline      = computed(() => !!data.value?.display?.displayoverline && !!data.value?.overline)
        const showtitle         = computed(() => !!data.value?.display?.showtitle && !!data.value?.title)
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        const data          = ref(null)
        const layoutprops   = ref({})
        const gridprops     = ref(null)
        const config        = ref(null)
        const frames        = ref(null)
        const addlframes    = ref([])

        const laststep      = ref(0)
        const progress      = ref(null)
        const controls      = ref(null)
        const controlprev   = ref(null)
        const controlnext   = ref(null)
        const pagination    = ref(null)
        const meta          = ref({
            title:       null,
            index:       null,
            totalslides: null,
            progress:    0
        })

        /**
         * Get a slide index by its ID
         *
         * @param {number} id The ID of a given slide
         * @returns {number} Returns the resulting index if found, 0 if otherwise
         */
        const getSlideIndexByID = (id) => {
            return [...frames.value?.keys()].findIndex(a => a == id) ?? 0
        }

        /**
         * Get a slide object by index
         *
         * @param {number} index Slide index
         * @returns object|null Returns an object literal representing a slide
         */
        const getSlideByIndex = (index) => {
            return [...frames.value?.values()][index] ?? null
        }

        const setup = (c) => {
            const conf = {
                ...c,
                rewind: c?.loop,
                skipTrack: true,
                dots: c?.controls ? pagination.value : null,
                arrows: {
                    prev: c.controls ? controlprev.value : null,
                    next: c.controls ? controlnext.value : null
                },
                responsive: [
                    {
                        breakpoint: 1920,
                        settings: {
                            slidesToShow: (c.slidesToShow ?? 3) * 2,
                            slidesToScroll: (c.slidesToScroll ?? 3) * 2,
                        }
                    },
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: c.slidesToShow ?? 3,
                            slidesToScroll: c.slidesToScroll ?? 3,
                        }
                    },
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 320,
                        settings: {
                            slidesToShow: 1.25,
                            slidesToScroll: 1.25,
                        }
                    }
                ]
            }
            const inst = glidercontainer.value
            glider.value = new Glider(inst, conf)

            // Initial update
            updateGliderMeta()

            // Autoplay
            if (conf?.autoplay) {
                autoplay()
            } else {
                // Scroll event support
                inst?.addEventListener('glider-slide-visible', (e) => {
                    updateGliderMeta()
                })
            }
        }

        const tween = async (v) => {
            const from  = (laststep.value)
            const to    = ((glider.value.slide + 1) / assets.value.size) * 100

            await nextTick()
            animate({
                from: from,
                to: to,
                type: 'spring',
                duration: 1500,
                onUpdate: latest => meta.value.progress = latest,
                onComplete: () => laststep.value = to
            })
        }

        const autoplay = async () => {
            const gi = glider.value

            __tick.value = useTick(60, (config.value?.autoplaydelay) ?? 5)
            __tick.value.addEventListener('onTick', (e) => {
                meta.value.progress = e.detail?.p
                // console.log(`> --------------------------- >> (debug) ${e.detail.debug}`)
            })
            __tick.value.addEventListener('onTickInterval', (e) => {
                __autoplayNext.value = ((gi.slide + 1) < (assets.value?.size - 1)) ? __autoplayNext.value + 1 : 0
                // console.clear()
                // console.group('onTickInterval')
                // console.log('>')
                // console.log(`> --------------------------- >> (${config.value?.autoplaydelay})`, gi.slide, (!gi.slide), __autoplayNext.value)
                // console.log(`> --------------------------- >> (debug) ${e.detail.debug}`)
                // console.log(`> --------------------------- >> (detail)`, e.detail)
                // console.log('>')
                // console.groupEnd()
                gi.scrollItem(__autoplayNext.value)
                updateGliderMeta()
                // meta.value.progress = 0
            })
            await nextTick()
            __tick.value.start()
        }

        const updateGliderMeta = () => {
            const gi        = glider.value
            const index     = gi?.slide ?? 0
            const slide     = getSlideByIndex(index)
            const i         = ((index * 2) / assets.value?.size) * 100
            meta.value.title        = `${index + 1} / ${assets.value?.size} - ${slide?.props?.title}`
            meta.value.index        = (index + 1)
            meta.value.totalslides  = assets.value?.size
            meta.value.progress     = clamp(i, 0, 100)

            if(!config?.autoplay) {
                tween(meta.value.progress)
            }
        }

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {layout} = useNormalizeLayoutProps(data)
                const {ratios} = useNormalizeRatioProps(data)
                const {grid, gap} = useNormalizeGridProps(data)
                layoutprops.value = {...layout.value, ...ratios.value, ...grid.value}
                gridprops.value = {...grid.value}
                if (!!gap.value) {
                    gridprops.value[gap.value] = ''
                }

                frames.value        = newvalue?.components
                addlframes.value    = [...newvalue?.components].slice(0, 3)
                config.value        = newvalue?.glider
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null

            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readCarouselContent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            layoutprops,
            gridprops,
            showtitle,
            showoverline,
            showcontent,
            showlinks,
            wrappedtitle,
            frames,
            addlframes,
            assets,
            addasset,
            glidercontainer,
            config,
            meta,
            progress,
            controls,
            controlprev,
            controlnext,
            pagination
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
