<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementaltriptych" :="layoutprops">
                <rs-grid  xxs-1 sm-12 span-xs-12 v-if="data?.assets?.length" :="panellayoutprops">
                    <rs-griditem v-if="showtitle || showcontent" :="contentgridprops" content>
                        <rs-heading v-if="showtitle">
                            <h2 heading ref="heading" scale="lg" weight="regular" casing="display-sm" gutters="0" caps v-html="wrappedtitle"></h2>
                        </rs-heading>
                        <rs-content v-if="showcontent" v-html="data?.content"></rs-content>
                        <rs-actions v-if="showlinks">
                            <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" :type="index == 0 ? 'contained' : 'outlined'" contained solid maxed />
                        </rs-actions>
                    </rs-griditem>

                    <rs-griditem v-if="data?.assets?.length" v-for="(item, index) in data?.assets" :key="`panel-${item?.id}`" :="gridprops" stackable media>
                        <Picture v-if="item?.id" stackable-item :config="item" :alt="item?.alt ?? data?.title" />
                    </rs-griditem>
                </rs-grid>
                <MDSkeleton v-else aspect-3-1 error="true" spin />
            </rs-container>
        </template>
        <template #fallback>
            <MDSkeleton aspect-21-9>
                <MDBlocker active="true" cursor="progress" type="default" />
            </MDSkeleton>
        </template>
    </Suspense>
</template>

<script>
import { ref, computed, watch, onBeforeMount, isProxy, toRaw } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { getAxiosInstance } from '@/javascript/lib/api'
import { useWrappedString, useComponentdata, useNormalizeLayoutProps, useNormalizeRatioProps } from '@/javascript/lib/composables'
import Picture from '@/components/Picture.ce.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'

export default {
    name: "UIElementalTriptych",
    components: {Picture, MDButton, MDBlocker, MDSkeleton},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const store         = useStore()
        const root          = ref(null)
        const heading       = ref(null)
        const __to          = ref(null)

        const api           = getAxiosInstance()
        const data          = ref(null)
        const layoutprops   = ref({})
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })
        const showcontent       = computed(() => !!data.value?.display?.displaycontent && data.value?.content)
        const showlinks         = computed(() => !!data.value?.display?.displaylinks && !!data.value?.links?.length)
        const showtitle         = computed(() => !!data.value?.display?.showtitle && !!data.value?.title)
        const panellayout       = ref(null)
        const panellayoutprops  = ref(null)
        const panelratios       = ref(null)
        const paneltemplate     = ref(null)
        const gridprops         = ref(null)
        const contentgridprops  = ref(null)

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })

        watch(data, (newvalue, oldvalue) => {
            const {layout} = useNormalizeLayoutProps(data)
            layoutprops.value = {...layout.value}

            const {ratios} = useNormalizeRatioProps(data)
            panelratios.value = {...ratios.value}

            panellayoutprops.value = {}
            if (data.value?.grid?.gap) {
                panellayoutprops.value[data.value?.grid?.gap] = ''
            }

            // Build an grid attribute list and merge with panelratios
            gridprops.value = gridprops.value ?? {}
            data.value?.grid?.span?.map( v => {
                gridprops.value[v] = ''
            })

            gridprops.value = {
                ...gridprops.value ?? {},
                ...panelratios.value ?? {}
            }

            // Generate props w/o the aspect-* attributes
            contentgridprops.value = {}
            Object.keys(toRaw(gridprops.value))?.map( v => {
                if (!v?.includes('aspect')) {
                    contentgridprops.value[v] = gridprops.value[v]
                }
            })

            paneltemplate.value = layoutprops.value?.template ?? 'triptych'

            switch (paneltemplate.value) {
                case 'diptych':
                    break;
                case 'triptych':
                default:

                    break;
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null

            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readTriptych', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            heading,
            data,
            showcontent,
            showlinks,
            showtitle,
            wrappedtitle,
            layoutprops,
            panellayout,
            panellayoutprops,
            paneltemplate,
            gridprops,
            contentgridprops
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
