<template>
    <Suspense>
        <template #default>
            <rs-container ref="root" rs-elemental="uielementalbanner" :="layoutprops">
                <rs-grid  xxs-1 sm-12 span-xs-6 ref="grid" :="gridprops" stackable>
                    <rs-griditem span-xs-1 span-sm-6 v-if="showtitle || showcontent || showlinks" content stackable-item>
                        <rs-heading v-if="showtitle">
                            <h2 weight="regular" casing="display-sm" gutters="0" caps v-html="wrappedtitle"></h2>
                            <h3 v-if="data?.subtitle" role="doc-subtitle" scale="subtitle-mm" weight="900" gutters="0" caps v-html="data?.subtitle"></h3>
                        </rs-heading>
                        <rs-content v-if="showcontent" :="contentprops" v-html="data.content" scale="subtitle-mm">
                        </rs-content>
                        <rs-actions v-if="showlinks">
                            <MDButton v-for="(item, index) in data?.links" tag="a" :href="item?.url" :target="item?.openinnewwindow ? '_blank' : null" :key="item?.key" :index="index" :label="item?.title" solid contained maxed rounded />
                        </rs-actions>
                    </rs-griditem>
                    <rs-griditem span-mm-4 media :="bgstyle" stackable-item>
                        <Picture :config="image" :alt="image?.alt ?? data?.title" :="style" stackable-item />
                    </rs-griditem>
                </rs-grid>
            </rs-container>
        </template>
    </Suspense>
</template>
<script>
import { ref, computed, watch, onBeforeMount } from 'vue';
import { useWrappedString , useComponentdata, useNormalizeLayoutProps, useNormalizeGridProps, useNormalizeRatioProps } from '@/javascript/lib/composables'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import Picture from '@/components/Picture.ce.vue'
import { useStore } from '@/javascript/lib/store'

export default {
    name: "UIElementalBanner",
    components: {MDButton, MDSkeleton, MDBlocker, Picture},
    props: {
        info: {
            type: String,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const root          = ref(null)
        const store         = useStore()
        const data          = ref(null)
        const layoutprops   = ref(null)
        const ratioprops    = ref(null)
        const contentprops  = ref(null)
        const gridprops     = ref(null)
        const grid          = ref(null)
        const image         = ref(null)
        const style         = ref(null)
        const bgstyle       = ref(null)
        const showcontent   = computed(() => data.value?.display?.displaycontent)
        const showtitle     = computed(() => data.value?.display?.showtitle)
        const showlinks     = computed(() => data.value?.display?.displaylinks && data.value?.links?.length)
        const wrappedtitle  = computed(() => {
            return useWrappedString(data.value?.title, [...data.value?.focustext] ?? [])
        })

        watch(root, (newvalue, oldvalue) => {
            if(newvalue instanceof HTMLElement) {
                store.observe(newvalue)
            }
        })

        watch(data, (newvalue, oldvalue) => {
            if (newvalue) {
                const {layout}      = useNormalizeLayoutProps(data)
                const {ratios}      = useNormalizeRatioProps(data)
                const {grid, gap}   = useNormalizeGridProps(data)
                // Extract the ratios as a value object, but DON'T combine with `layoutprops`
                ratioprops.value    = {...ratios.value}
                layoutprops.value   = {...layout.value, ...grid.value}


                gridprops.value = {...grid.value, ...ratioprops.value}
                if (!!gap.value) {
                    gridprops.value[gap.value] = ''
                }

                image.value = {...data.value?.image}

                const bgstylevalues = []
                const stylevalues = []
                data.value?.layout?.style?.map( v => {
                    if (['opacity','mix-blend-mode'].includes(v.key)) {
                        stylevalues.push(`${v.key}: ${v.value}`)
                    } else {
                        bgstylevalues.push(`${v.key}: ${v.value}`)
                    }
                })

                bgstyle.value  = {style: bgstylevalues.join(";")}
                style.value = {style: stylevalues.join(";")}
            }
        })

        onBeforeMount(() => {
            const params = props.info ? JSON.parse(props?.info) : null
            if (params?.constructor == Object && !!params?.prefetch) {
                useComponentdata({...params, query: 'readComponent', data: data})
            }else{
                if(!!props.config) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            data,
            grid,
            gridprops,
            layoutprops,
            ratioprops,
            contentprops,
            showtitle,
            showcontent,
            showlinks,
            image,
            style,
            bgstyle,
            wrappedtitle
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
