<template>
    <map ref="mapcontainer">
        <!-- Google Map Goes Here -->
    </map>
</template>

<script>
import { ref, watch, computed, onBeforeMount, onMounted, createApp } from 'vue'
import { useStore } from '@/javascript/lib/store'
import InfoWindow from '@/components/InfoWindow.vue'
import IconDefault from '@/assets/marker.png?url'

export default {
    name: "GoogleMap",
    components: {InfoWindow},
    props: {
        config: {
            type: Object,
            default() {
                return null
            }
        },
        markers: {
            type: Array,
            default() {
                return null
            }
        },
        key: {
            type: String,
            default() {
                return null
            }
        },
        selected: {
            type: String,
            default() {
                return null
            }
        },
        center: {
            type: Object,
            default() {
                return {
                    lat: 49.1535272,
                    lng: -122.8532013
                }
            }
        }
    },
    setup(props) {
        const data          = ref(null)
        const map           = ref(null)
        const key           = ref(null)
        const mapcontainer  = ref(null)
        const infoinstance  = ref(null)
        const center        = ref(null)
        const store         =  useStore()
        const styles = [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#CEDEBA"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#414141"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural.terrain",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#e0ebe9"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        // {
                        //     "saturation": -100
                        // },
                        // {
                        //     "lightness": 45
                        // }
                        {
                            "color": "#FBF8E4"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#46bcec"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#96D7E0"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                }
            ]
        const options       = ref({
            zoom: 7,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            styles: styles
        })
        const markers = ref(null)

        /**
         * This watch triggers the rendering process when markers ref is updated
         */
        watch(() => props.markers, (newvalue, oldvalue) => {
            if (newvalue) {
                if (Array.isArray(props.markers) && props.markers?.length) {
                    process(props.markers)
                }
            }
        })

        /**
         * This watch handles the state change with store.selectedmarker,
         * triggering the infowindow for a given marker
         */
        watch(() => store.selectedmarker, (n, o) => {
            if(!!store.selectedmarker && markers.value.has(store.selectedmarker)) {
                const marker = markers.value.get(store.selectedmarker)
                show(marker)
            }
        })

        /**
         * Handle prop.selected value changes from the host component
         */
        watch(() => props.selected, (newvalue, oldvalue) => {
            if (newvalue) {
                const uuid = props.selected
                const m = markers.value.has(uuid) ? markers.value.get(uuid) : null
                let __to = null
                if (m) {
                    closeInfoWindows()
                    map.value.setZoom(10)

                    __to = setTimeout(() => {

                        // Pan and zoom to marker
                        map.value.panTo(m.position)
                        map.value.setZoom(18)

                        // Show the marker
                        show(m)

                        clearTimeout(__to)
                    }, (320 * 3))
                }
            }
        })

        /**
         * Initialize Google Maps
         */
        const init = () => {
            // Update google map options
            options.value.center      = new google.maps.LatLng(center.value.lat, center.value.lng)
            options.value.MapTypeId   = google.maps.MapTypeId.ROADMAP
            options.value.zoomControlOptions = {
                position: google.maps.ControlPosition.TOP_RIGHT
            }
            map.value = new google.maps.Map(mapcontainer.value, options.value)

            // Begin processing markers
            markers.value = new Map()
            if (Array.isArray(props.markers) && props.markers.length) {
                process(props.markers)
            }
        }

        /**
         * Reset the state of markers
         *
         * @todo this isn't resetting the markers on the map, and could be the result of it being a reactive property
         */
        const reset = () => {
            if (markers.value?.size) {
                markers.value?.forEach( (v, k, m) => {
                    v.setMap(null)
                })
            }
        }

        /**
         * Process a collection of raw marker data
         *
         * @param {array} data A collection of object literals containing marker data
         */
        const process = (data) => {
            if (data) {
                reset()
                const b = new google.maps.LatLngBounds()
                data.map((v) => {
                    const props = {...v}
                    const location = {
                        ...props.geometry?.location ?? {}
                    }
                    if (location?.lat && location?.lng) {

                        const marker = new google.maps.Marker({
                            position: new google.maps.LatLng(location.lat, location.lng),
                            map: map.value,
                            title: props.title,
                            icon: {
                                url:        getIconByRegion(),
                                size:       new google.maps.Size(32, 32)
                            },
                            feature: new google.maps.Data.Feature({
                                geometry: location,
                                properties: props
                            })
                        })

                        b.extend(marker.position)

                        // Store the marker for later reference
                        markers.value.set(props?.uuid, marker)

                        // Click handler for markers
                        google.maps.event.addListener(marker, "click", () => {
                            show(marker)
                        })
                    } else {
                        if (['local', 'localhost', 'dev'].includes(store.mode)) {
                            console.warn(`Unable to render marker. "${props.title}" does not contain valid location data.`)
                        }
                    }
                })

                // Re-center and fit to bounds
                map.value.setCenter(b.getCenter())
                map.value.fitBounds(b)
            }
        }

        /**
         * Show the info window for a given marker
         *
         * @param {google.maps.Marker} marker A Google Maps Marker instance
         */
         const show = (marker) => {
            closeInfoWindows()

            if(marker instanceof google.maps.Marker) {
                const data = {
                    content: getInfoWindow(marker),
                    maxWidth: 300
                }
                const w = new google.maps.InfoWindow(data)
                if (w){
                    infoinstance.value = w
                    w.open(map, marker)
                    map.value?.panTo(marker.getPosition())

                    // Click handler for infowindow close button
                    google.maps.event.addListener(w, "closeclick", () => {
                        closeInfoWindows()
                    })

                    store.selectedmarker = getMarkerData(marker)?.uuid
                }
            }
        }

        const closeInfoWindows = () => {
            infoinstance.value?.close()
            infoinstance.value = null
            store.selectedmarker = null
        }

        /**
         * Get an HTMLElement instance for use in an infoWindow
         *
         * @param {google.maps.Marker} marker Marker instnace
         */
         const getInfoWindow = (marker) => {
            if (marker instanceof google.maps.Marker) {
                const data      = getMarkerData(marker)
                const template  = document.createElement('template')
                let instance    = createApp(InfoWindow, {config: data}).mount(document.createElement('template'))
                template.innerHTML = instance.$el.cloneNode(true).outerHTML;
                instance = null
                const content = template.content.querySelector('info')
                return content
            }
            return null
        }

        /**
         * Thin utility wrapper around google.maps.Marker.forEachProperty method
         *
         * @param {google.maps.Marker|null} marker Returns a google maps marker instance
         */
        const getMarkerData = (marker) => {
            if (marker instanceof google.maps.Marker) {
                const data = {}
                marker.feature.forEachProperty( (v, k) => {
                    data[k] = v
                })
                return data
            }
            return null
        }

        /**
         * Get an icon
         */
         const getIconByRegion = () => {
            const uri = new URL(IconDefault, import.meta.url)
            return uri?.toString()
        }

        onBeforeMount(() => {
            data.value = props.config?.props
            key.value = props?.key ?? store?.googlemaps_api_key
            center.value = props?.center ?? null
        })

        onMounted(() => {
            if (!document.querySelector('script#googlemapsjsapi')) {
                const url = `https://maps.googleapis.com/maps/api/js?key=${key.value}`
                const script = document.createElement('script')
                script.setAttribute('aysnc','')
                script.setAttribute('defer','')
                script.setAttribute('src', url)
                script.setAttribute('id','googlemapsjsapi')
                script.addEventListener('load', (e) => {
                    init()
                })
                document.head.append(script)
            } else {
                init()
            }
        })

        return {
            map,
            data,
            mapcontainer,
            center
        }
    }
}
</script>
