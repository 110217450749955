<template>

    <rs-grid ref="root" rs-elemental="uielementalglider" :="$attrs"  xxs-1 sm-12 stackable carousel>

        <glider v-if="frames?.length" ref="glidercontainer" stackable-item>
            <div class="glider-track">
                <template v-for="(item, index) in frames" :key="`slide-${index}`">
                    <UIElementalFrame :ref="(el) => addasset(el)" :config="item"></UIElementalFrame>
                </template>
            </div>
        </glider>

        <template v-if="!!config?.controls">
            <div ref="progress" class="progress-container"><progress max="100" :value="meta?.progress ?? 0" class="progress progress--root"></progress></div>
            <controls ref="controls">
                <button ref="controlprev" prev aria-label="Previous" class="glider-prev" stackable>
                    <span label stackable-item easing>
                        <slidestats>
                            <stat current>{{ meta?.index }}</stat>
                            <stat total>{{ meta?.totalslides }}</stat>
                        </slidestats>
                        <icon class="md-icon" easing>chevron_left</icon>
                    </span>
                </button>
                <button ref="controlnext" next aria-label="Next" class="glider-next" stackable>
                    <span label stackable-item easing>
                        <slidestats>
                            <stat current>{{ meta?.index }}</stat>
                            <stat total>{{ meta?.totalslides }}</stat>
                        </slidestats>
                        <icon class="md-icon" easing>chevron_right</icon>
                    </span>
                </button>
            </controls>
            <pagination ref="pagination" role="tablist" class="dots"></pagination>
        </template>

    </rs-grid>

</template>

<script>
import { ref, computed, watch, onBeforeMount, onMounted, nextTick } from 'vue'
import { useStore } from '@/javascript/lib/store'
import { animate } from "popmotion"
import { getAxiosInstance, axioscacheconfig } from '@/javascript/lib/api'
import { useTick } from '@/javascript/lib/tick'
import { debounce, throttle, clamp, once } from '@/javascript/lib/utils'
import Picture from '@/components/Picture.ce.vue'
import MDSkeleton from '@/components/MDSkeleton.ce.vue'
import MDButton from '@/components/MDButton.ce.vue'
import MDBlocker from '@/components/MDBlocker.vue'
import UIElementalFrame from '@/components/UIElementalFrame.ce.vue'
import Glider from 'glider-js'

export default {
    name: "UIElementalGlider",
    components: {Picture, UIElementalFrame, MDButton, MDBlocker, MDSkeleton},
    props: {
        slides: {
            type: Array,
            default() {
                return null
            }
        },
        config: {
            type: Object,
            default() {
                return null
            }
        }
    },
    setup(props) {
        const __tick            = ref(null)
        const __autoplayNext    = ref(0)

        const store             = useStore()
        const root              = ref(null)
        const glidercontainer   = ref(null)
        const glider            = ref(null)
        const assets        = ref(new Set())
        const addasset      = async (asset) => {
            if(!assets.value?.has(asset) && (assets.value?.size < frames.value?.length)) {
                assets.value?.add(asset)
                if ((assets.value?.size == frames.value?.length) && config.value) {
                    await nextTick()
                    setup(config.value)
                }
            }
        }

        const api           = getAxiosInstance()
        const data          = ref(null)
        const layoutprops   = ref({})
        const config        = ref(null)
        const frames        = ref(null)

        const laststep      = ref(0)
        const progress      = ref(null)
        const controls      = ref(null)
        const controlprev   = ref(null)
        const controlnext   = ref(null)
        const pagination    = ref(null)
        const meta          = ref({
            title:       null,
            index:       null,
            totalslides: null,
            progress:    0
        })

        /**
         * Get a slide index by its ID
         *
         * @param {number} id The ID of a given slide
         * @returns {number} Returns the resulting index if found, 0 if otherwise
         */
        const getSlideIndexByID = (id) => {
            return [...frames.value?.keys()].findIndex(a => a == id) ?? 0
        }

        /**
         * Get a slide object by index
         *
         * @param {number} index Slide index
         * @returns object|null Returns an object literal representing a slide
         */
        const getSlideByIndex = (index) => {
            return [...frames.value?.values()][index] ?? null
        }

        const setup = (c) => {
            const conf = {
                ...c,
                rewind: c?.loop,
                skipTrack: true,
                dots: c.controls ? pagination.value : null,
                arrows: {
                    prev: c.controls ? controlprev.value : null,
                    next: c.controls ? controlnext.value : null
                }
            }
            const inst = glidercontainer.value
            glider.value = new Glider(inst, conf)

            // Initial update
            updateGliderMeta()

            // Autoplay
            if (conf?.autoplay) {
                autoplay()
            } else {
                // Scroll event support
                inst?.addEventListener('glider-slide-visible', (e) => {
                    updateGliderMeta()
                })
            }
        }

        const tween = async (v) => {
            const from  = (laststep.value)
            const to    = ((glider.value.slide + 1) / assets.value.size) * 100

            await nextTick()
            animate({
                from: from,
                to: to,
                type: 'spring',
                duration: 1500,
                onUpdate: latest => meta.value.progress = latest,
                onComplete: () => laststep.value = to
            })
        }

        const autoplay = async () => {
            const gi = glider.value

            __tick.value = useTick(60, (config.value?.autoplaydelay) ?? 5)
            __tick.value.addEventListener('onTick', (e) => {
                meta.value.progress = e.detail?.p
                // console.log(`> --------------------------- >> (debug) ${e.detail.debug}`)
            })
            __tick.value.addEventListener('onTickInterval', (e) => {
                __autoplayNext.value = ((gi.slide + 1) < (assets.value?.size - 1)) ? __autoplayNext.value + 1 : 0
                // console.clear()
                // console.group('onTickInterval')
                // console.log('>')
                // console.log(`> --------------------------- >> (${config.value?.autoplaydelay})`, gi.slide, (!gi.slide), __autoplayNext.value)
                // console.log(`> --------------------------- >> (debug) ${e.detail.debug}`)
                // console.log(`> --------------------------- >> (detail)`, e.detail)
                // console.log('>')
                // console.groupEnd()
                gi.scrollItem(__autoplayNext.value)
                updateGliderMeta()
                // meta.value.progress = 0
            })
            await nextTick()
            __tick.value.start()
        }

        const updateGliderMeta = () => {
            const gi        = glider.value
            const index     = gi?.slide ?? 0
            const slide     = getSlideByIndex(index)
            const i         = ((index * 2) / assets.value?.size) * 100
            meta.value.title        = `${index + 1} / ${assets.value?.size} - ${slide?.props?.title}`
            meta.value.index        = (index + 1)
            meta.value.totalslides  = assets.value?.size
            meta.value.progress     = clamp(i, 0, 100)

            if(!config?.autoplay) {
                tween(meta.value.progress)
            }
        }

        watch(frames, (newvalue, oldvalue) => {
            if (newvalue) {
                frames.value = newvalue
            }
        })

        onBeforeMount(() => {
            if (Array.isArray(props.slides)) {
                frames.value = [...props?.slides]
            }
            if (props.config?.constructor == Object) {
                config.value = props?.config
            }else{
                if(props.config instanceof String) {
                    data.value = (JSON.parse(props.config))?.props
                }
            }
        })

        return {
            root,
            layoutprops,
            frames,
            assets,
            addasset,
            glidercontainer,
            config,
            meta,
            progress,
            controls,
            controlprev,
            controlnext,
            pagination,
            store
        }
    }
}
</script>

<style lang="scss">
@include foundation();
</style>
